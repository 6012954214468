import Dexie from "dexie"
import sha256 from "crypto-js/sha256"

export const eventStates = {
  CREATED: "created",
  SYNCHRONIZING: "synchronizing",
  SYNCHRONIZED: "synchronized",
}

export class EventDatabase extends Dexie {
  constructor() {
    super("evalmee-fs")

    // Define database schema with indexes
    this.version(1)
      .stores({
        "flying-squirrel": null, // Mark for deletion
        "swimming-racoon": null, // Mark for deletion
      })

    this.version(2)
      .stores({
        events: "++id, quizId, [quizId+state], exerciseId, scoreId, happenedAt, h, state",
        screenshots: "++id, quizId, [quizId+state], scoreId, happenedAt, h, state",
      })
      .upgrade(tx => this.migrateData(tx))

    // Handle blocked upgrade
    this.on("blocked", () => {
      const reload = window.confirm(
        "Please close any other Evalmee window or tab then click on 'Ok' \n\n Merci de fermer toutes les autres fenêtres ou onglets Evalmee avant de cliquer sur 'Ok'"
      )
      if (reload) {
        window.location.reload()
      }
    })
  }

  async migrateData(tx) {
    try {
      // Attempt to open old stores
      const oldDb = await new Dexie("evalmee-fs").open()

      if (oldDb.tables.some(table => table.name === "flying-squirrel")) {
        console.log("Migrating events from old store...")
        const oldEvents = await oldDb.table("flying-squirrel").toArray()
        await tx.events.bulkAdd(oldEvents)
        console.log(`Migrated ${oldEvents.length} events`)
      }

      if (oldDb.tables.some(table => table.name === "swimming-racoon")) {
        console.log("Migrating screenshots from old store...")
        const oldScreenshots = await oldDb.table("swimming-racoon").toArray()
        await tx.screenshots.bulkAdd(oldScreenshots)
        console.log(`Migrated ${oldScreenshots.length} screenshots`)
      }

      await oldDb.close()
    } catch (error) {
      console.log("No old data to migrate or error during migration:", error)
    }
  }

  /**
   * Returns a sha256 hash of the event
   *
   * @param event
   * @return {string}
   */
  eventHash(event) {
    const attributes = {
      index: event.id,
      kind: event.kind,
      quiz_id: event.quizId,
      score_id: event.scoreId,
      exercise_id: event.exerciseId,
      happened_at: Math.floor(event.happenedAt / 1000),
    }

    const nonNullAttributes = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(attributes).filter(([_, v]) => v != null)
    )

    return sha256(JSON.stringify(nonNullAttributes)).toString()
  }
}
