import { DirectUpload } from "activestorage"
import api from "../api"
import axios from "axios"
import { EventDatabase, eventStates } from "./eventDatabase"
import stringHelpers from "./string_helpers"

const EVENTS_BATCH_SIZE = 1000
const SCREENSHOTS_BATCH_SIZE = 30

const blobToArrayBuffer = async (file) => {
  return {
    data: await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    }),
    name: file.name,
    type: file.type,
    lastModified: file.lastModified,
  }
}

const arrayBufferToFile = (storedData) => {
  const blob = new Blob([storedData.data], { type: storedData.type })
  return new File([blob], storedData.name, {
    type: storedData.type,
    lastModified: storedData.lastModified,
  })
}

// Utility function to split an array into chunks
const chunkArray = (array, size) => {
  const chunks = []
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size))
  }
  return chunks
}

// Function to sanitize object containing potential invalid UTF-8 characters
const sanitizeDetails = (details) => {
  if (!details) return details

  if (typeof details === "string") {
    return stringHelpers.sanitizeString(details)
  } else if (Array.isArray(details)) {
    return details.map(item => sanitizeDetails(item))
  } else if (typeof details === "object" && details !== null) {
    const sanitized = {}
    for (const key in details) {
      if (Object.prototype.hasOwnProperty.call(details, key)) {
        sanitized[key] = sanitizeDetails(details[key])
      }
    }
    return sanitized
  }

  // Return primitives as is
  return details
}

export class EventStorage {
  constructor(quizId) {
    this.quizId = quizId
    this.db = new EventDatabase()
  }

  async storeEvent({ type, exerciseId, scoreId, details, happenedAt } = { details: {}, happenedAt: new Date() }) {
    const event = {
      kind: type,
      details: sanitizeDetails(details),
      quizId: this.quizId,
      exerciseId,
      scoreId,
      happenedAt: happenedAt || new Date(),
      state: eventStates.CREATED,
    }

    // With Dexie, the id is automatically generated and returned
    const id = await this.db.events.add(event)
    event.id = id
    event.h = this.db.eventHash(event)

    await this.db.events.update(id, { h: event.h })
  }

  async storeScreenshot({ scoreId, happenedAt, imageFile, imageSignedId }) {
    const screenshot = {
      kind: "screenshot",
      quizId: this.quizId,
      scoreId,
      imageSignedId,
      happenedAt: happenedAt || new Date(),
      state: eventStates.CREATED,
    }

    if (imageFile) {
      try {
        const storedData = await blobToArrayBuffer(imageFile)
        screenshot.imageFileData = storedData
      } catch (error) {
        console.error("Error converting image file to ArrayBuffer:", error)
        throw error
      }
    }

    const id = await this.db.screenshots.add(screenshot)
    screenshot.id = id
    screenshot.h = this.db.eventHash(screenshot)

    await this.db.screenshots.update(id, { h: screenshot.h })
  }

  async syncAllEvents() {
    // Get all events to synchronize
    const eventsToSync = await this.db.events
      .where(["quizId", "state"])
      .equals([this.quizId, eventStates.CREATED])
      .toArray()

    // Group events by scoreId
    const eventsByScore = eventsToSync.reduce((acc, event) => {
      acc[event.scoreId] = acc[event.scoreId] || []
      acc[event.scoreId].push(event)
      return acc
    }, {})

    // Synchronize events by group and batches
    for (const [scoreId, scoreEvents] of Object.entries(eventsByScore)) {
      // Split events into EVENTS_BATCH_SIZE chunks
      const eventBatches = chunkArray(scoreEvents, EVENTS_BATCH_SIZE)

      for (const eventBatch of eventBatches) {
        try {
          const response = await axios.post(
            `${api.rootUrl()}/api/v1/quizzes/attempts/${scoreId}/student_events`,
            {
              student_event: {
                score_id: scoreId,
                events: eventBatch.map(event => ({
                  exercise_id: event.exerciseId,
                  event: event.kind,
                  happened_at: event.happenedAt,
                  details: sanitizeDetails(event.details),
                  hash: event.h,
                  index: event.id,
                })),
              },
            }
          )

          if (response.status >= 200 && response.status < 300) {
            await this.db.events.bulkDelete(eventBatch.map(e => e.id))
          }
        } catch (error) {
          if (error.response?.status === 404) {
            await this.db.events.bulkDelete(eventBatch.map(e => e.id))
            console.info("Score not found, marking events as synchronized:", eventBatch)
          } else {
            console.error("Error syncing events:", error)
          }
        }
      }
    }

    // Get all screenshots to synchronize
    const screenshotsToSync = await this.db.screenshots
      .where(["quizId", "state"])
      .equals([this.quizId, eventStates.CREATED])
      .toArray()

    // Group screenshots by scoreId
    const screenshotsByScore = screenshotsToSync.reduce((acc, screenshot) => {
      acc[screenshot.scoreId] = acc[screenshot.scoreId] || []
      acc[screenshot.scoreId].push(screenshot)
      return acc
    }, {})

    // Synchronize screenshots by group and batches
    for (const [scoreId, scoreScreenshots] of Object.entries(screenshotsByScore)) {
      // Split screenshots into SCREENSHOTS_BATCH_SIZE chunks
      const screenshotBatches = chunkArray(scoreScreenshots, SCREENSHOTS_BATCH_SIZE)

      for (const screenshotBatch of screenshotBatches) {
        try {
          // Prepare all screenshots in the batch with their signed IDs
          const screenshotsWithSignedIds = await Promise.all(
            screenshotBatch.map(async screenshot => {
              if (screenshot.imageSignedId) return screenshot

              let imageFile
              if (screenshot.imageFileData) {
                imageFile = arrayBufferToFile(screenshot.imageFileData)
              } else {
                imageFile = screenshot.imageFile
              }

              if (!imageFile) {
                console.error("No image file found for screenshot:", screenshot)
                return null
              }

              const signedId = await new Promise((resolve) => {
                const upload = new DirectUpload(imageFile, api.activeStorageDirectUploadUrl())
                upload.create((error, blob) => {
                  if (error) {
                    console.error("Error uploading screenshot:", error)
                    resolve(null)
                    return
                  }
                  resolve(blob.signed_id)
                })
              })

              return signedId ? { ...screenshot, imageSignedId: signedId } : null
            })
          )

          const validScreenshots = screenshotsWithSignedIds.filter(Boolean)
          if (validScreenshots.length === 0) continue

          const response = await axios.post(
            `${api.rootUrl()}/api/v1/quizzes/attempts/${scoreId}/student_screenshots`,
            {
              student_screenshot: {
                score_id: scoreId,
                screenshots: validScreenshots.map(screenshot => ({
                  happened_at: screenshot.happenedAt,
                  frontend_hash: screenshot.h,
                  frontend_index: screenshot.id,
                  image: screenshot.imageSignedId,
                })),
              },
            }
          )

          if (response.status >= 200 && response.status < 300) {
            await this.db.screenshots.bulkDelete(validScreenshots.map(s => s.id))
          }
        } catch (error) {
          if (error.response?.status === 404) {
            await this.db.screenshots.bulkDelete(screenshotBatch.map(s => s.id))
            console.info("Score not found, marking screenshots as synchronized:", screenshotBatch)
          } else {
            console.error("Error syncing screenshots:", error)
          }
        }
      }
    }
  }

  async getAllEvents() {
    return this.db.events
      .where("quizId")
      .equals(this.quizId)
      .toArray()
  }

  async getAllScreenshots() {
    return this.db.screenshots
      .where("quizId")
      .equals(this.quizId)
      .toArray()
  }

  async getUnsyncedEventsCount() {
    if (!this.quizId) {
      return await this.db.events
        .where("state")
        .equals(eventStates.CREATED)
        .count()
    }

    return await this.db.events
      .where(["quizId", "state"])
      .equals([this.quizId, eventStates.CREATED])
      .count()
  }

  async getUnsyncedScreenshotsCount() {
    if (!this.quizId) {
      return await this.db.screenshots
        .where("state")
        .equals(eventStates.CREATED)
        .count()
    }

    return await this.db.screenshots
      .where(["quizId", "state"])
      .equals([this.quizId, eventStates.CREATED])
      .count()
  }

  async hasUnsyncedItems() {
    const [eventsCount, screenshotsCount] = await Promise.all([
      this.getUnsyncedEventsCount(),
      this.getUnsyncedScreenshotsCount(),
    ])

    return eventsCount > 0 || screenshotsCount > 0
  }
}
