import { EventStorage } from "@/helpers/studentEventStore"
import ws from "@/web_sockets"
import { ref } from "vue"
import { v1 as uuidv1 } from "uuid"
import { createGlobalState } from "@vueuse/core"
import { computed } from "vue"
import { useDebounceFn } from "@vueuse/core"

// Used to store the last events for debugging purposes only
const lastEvents = ref([])

/**
 * @description This function generates a new UUID v1 token for each browser tab. The token identifies the browser tab and is refreshed on each page reload.
 * @returns {function} A function that returns a new UUID v1 token
 */
const browserTabToken = createGlobalState(uuidv1)

const isOutsideOfExam = createGlobalState(() => {
  return ref(false)
})
const hasSuspectBehavior = createGlobalState(() => {
  return ref(false)
})
const hasRecentBrowserExtensionInteraction = createGlobalState(() => {
  return ref(false)
})

const screenCount = createGlobalState(() => {
  return ref(0)
})

const screenSharingError = createGlobalState(() => {
  return ref(null)
})

/**
 * @description This state is used to store the type of the shared screen.
 * Possible values are: "monitor", "window", "browser"
 */
const sharedScreenSurface = createGlobalState(() => {
  return ref(null)
})

const isScreenShared = createGlobalState(() => {
  return ref(false)
})

const metSharingScreenRequirements = computed(() => {
  return sharedScreenSurface().value === "monitor" && screenCount().value === 1 && isScreenShared().value
})

// Simple global debounced sync function
const debouncedSync = createGlobalState(() =>
  useDebounceFn(
    async (storage) => {
      await storage.syncAllEvents()
      // Get the current storage instance to update its counts
      const [events, screenshots] = await Promise.all([
        storage.getUnsyncedEventsCount(),
        storage.getUnsyncedScreenshotsCount(),
      ])
      unsyncedEvents().value = events
      unsyncedScreenshots().value = screenshots
    },
    5000,
    { maxWait: 20000 }
  )
)

// Global refs for unsynced counts
const unsyncedEvents = createGlobalState(() => ref(0))
const unsyncedScreenshots = createGlobalState(() => ref(0))

export function useStudentEventRegisterer(quizId, scoreId) {
  if(quizId) ws.channels.QuizStudentEvents.subscribe({ quizId: quizId })

  const eventStorage = new EventStorage(quizId)

  // Update counts when events change
  const updateUnsyncedCounts = async () => {
    const [events, screenshots] = await Promise.all([
      eventStorage.getUnsyncedEventsCount(),
      eventStorage.getUnsyncedScreenshotsCount(),
    ])
    unsyncedEvents().value = events
    unsyncedScreenshots().value = screenshots
  }

  // Initial count
  updateUnsyncedCounts()

  const register = ({ event, exerciseId, happenedAt, details } = { details: {} }) => {
    const detailsWithTabToken = { ...details, browser_tab_token: browserTabToken() }

    lastEvents.value.push({ event, exerciseId, scoreId, details: detailsWithTabToken, happenedAt })

    return eventStorage
      .storeEvent({ type: event, details: detailsWithTabToken, scoreId, exerciseId, happenedAt })
      .then(() => {
        updateUnsyncedCounts()
        return debouncedSync()(eventStorage)
      })
  }

  return {
    register,
    lastEvents,
    isOutsideOfExam,
    hasSuspectBehavior,
    screenCount,
    sharedScreenSurface,
    metSharingScreenRequirements,
    isScreenShared,
    screenSharingError,
    hasRecentBrowserExtensionInteraction,
    unsyncedEvents: unsyncedEvents(),
    unsyncedScreenshots: unsyncedScreenshots(),
  }
}
